import { Project, Test } from '@analyzer/client';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect, useReducer, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useProjectReadQuery } from '../api/project.js';
import { useTestListQuery, useTestReadQuery } from '../api/test.js';
import { AnalysisList } from '../components/analysis/list';
import { DestroyProjectComponent } from '../components/project/destroy';
import { SnapshotsList } from '../components/snapshot/list';
import { NewSnapshotComponent } from '../components/snapshot/new';
import { DeleteTestDialog } from '../components/test/dialogs/delete.js';
import { EditTestDialog } from '../components/test/dialogs/edit.js';
import { NewTestDialog } from '../components/test/dialogs/new.js';
import { SnapshotDialog } from '../components/test/dialogs/snapshot.js';
import { DisableTestDialog } from '../components/test/dialogs/disable.js';

type Action =
  | { type: 'OPEN_MODAL'; modal: 'new' | 'edit' | 'delete' | 'disable' | 'snapshot' }
  | { type: 'CLOSE_MODAL'; modal: 'new' | 'edit' | 'delete' | 'disable' | 'snapshot' }
  | { type: 'SET_PROJECT'; project: Project }
  | { type: 'SET_TEST'; test: Test };

interface State {
  new: boolean;
  edit: boolean;
  delete: boolean;
  disable: boolean;
  snapshot: boolean;
  project: Project | undefined;
  test: Test | undefined;
}

const initialState: State = {
  new: false,
  edit: false,
  delete: false,
  disable: false,
  snapshot: false,
  test: undefined,
  project: undefined
};

function modalReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'OPEN_MODAL':
      return { ...state, [action.modal]: true };
    case 'CLOSE_MODAL':
      return { ...state, [action.modal]: false };
    case 'SET_PROJECT':
      return { ...state, project: action.project };
    case 'SET_TEST':
      return { ...state, test: action.test };
    default:
      return state;
  }
}

export function TestsView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter');

  const { enqueueSnackbar } = useSnackbar();
  const [state, dispatch] = useReducer(modalReducer, initialState);

  const { data: tests, isLoading, isError } = useTestListQuery(parseInt(projectId));
  const { data: project } = useProjectReadQuery(parseInt(projectId));

  useEffect(() => {
    if (project) {
      dispatch({ type: 'SET_PROJECT', project });
    }
  }, [project]);

  const handleCreateClick = () => {
    dispatch({ type: 'OPEN_MODAL', modal: 'new' });
  };

  const handleEditClick = (test: Test) => {
    dispatch({ type: 'SET_TEST', test });
    dispatch({ type: 'OPEN_MODAL', modal: 'edit' });
  };

  const handleDeleteClick = (test: Test) => {
    dispatch({ type: 'SET_TEST', test });
    dispatch({ type: 'OPEN_MODAL', modal: 'delete' });
  };

  const handleDisableClick = (test: Test) => {
    dispatch({ type: 'SET_TEST', test });
    dispatch({ type: 'OPEN_MODAL', modal: 'disable' });
  };

  const handleSnapshotClick = (test: Test) => {
    dispatch({ type: 'SET_TEST', test });
    dispatch({ type: 'OPEN_MODAL', modal: 'snapshot' });
  };

  if (isLoading) {
    return (
      <Backdrop open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (isError || !tests) {
    return <Typography variant="body1">Unable to load tests. Please try again later.</Typography>;
  }

  return (
    <Box>
      <Typography variant="h5">Tests</Typography>
      <Button variant="contained" color="primary" onClick={handleCreateClick} size="small">
        Create
      </Button>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Target</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests.map((test) => (
              <TableRow key={test.id}>
                <TableCell>{test.id}</TableCell>
                <TableCell>{test.name}</TableCell>
                <TableCell>
                  <Link to={test.target}>{test.target}</Link>
                </TableCell>
                <TableCell>
                  <ButtonGroup size="small">
                    <Button onClick={() => handleSnapshotClick(test)}>Snapshot</Button>
                    <Button onClick={() => handleEditClick(test)}>Edit</Button>
                    <Button onClick={() => handleDeleteClick(test)}>Delete</Button>
                    <Button onClick={() => handleDisableClick(test)}>
                      {test.enabled ? 'Disable' : 'Enable'}
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <NewTestDialog
        project={state.project}
        open={state.new}
        onClose={() => dispatch({ type: 'CLOSE_MODAL', modal: 'new' })}
        onConfirm={() => enqueueSnackbar('New test created successfully', { variant: 'success' })}
        onError={() => enqueueSnackbar('Error: Unable to create new test', { variant: 'error' })}
      />

      <EditTestDialog
        project={state.project}
        test={state.test}
        open={state.edit}
        onClose={() => dispatch({ type: 'CLOSE_MODAL', modal: 'edit' })}
        onConfirm={() => enqueueSnackbar('Test updated successfully', { variant: 'success' })}
        onError={() => enqueueSnackbar('Error: Failed to update the test', { variant: 'error' })}
      />

      <DeleteTestDialog
        project={state.project}
        test={state.test}
        open={state.delete}
        onClose={() => dispatch({ type: 'CLOSE_MODAL', modal: 'delete' })}
        onConfirm={() => enqueueSnackbar('Test deleted successfully', { variant: 'success' })}
        onError={() => enqueueSnackbar('Error: Failed to delete the test', { variant: 'error' })}
      />

      <DisableTestDialog
        project={state.project}
        test={state.test}
        open={state.disable}
        onClose={() => dispatch({ type: 'CLOSE_MODAL', modal: 'disable' })}
        onConfirm={() => enqueueSnackbar('Test disabled successfully', { variant: 'success' })}
        onError={() => enqueueSnackbar('Error: Unable to disable the test', { variant: 'error' })}
      />

      <SnapshotDialog
        project={state.project}
        test={state.test}
        open={state.snapshot}
        onClose={() => dispatch({ type: 'CLOSE_MODAL', modal: 'snapshot' })}
        onConfirm={() => enqueueSnackbar('Snapshot job submitted successfully', { variant: 'success' })}
        onError={() => enqueueSnackbar('Error: Failed to submit snapshot job', { variant: 'error' })}
      />
    </Box>
  );
}

type TestViewParams = {
  projectId: string;
  testId: string;
};

export function TestView() {
  const { projectId, testId } = useParams<TestViewParams>();
  if (!testId || !projectId) throw new Error('Missing parameter');

  const [value, setValue] = useState(0);
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const test = useTestReadQuery(parseInt(projectId), parseInt(testId));
  if (test.isLoading) return <CircularProgress />;
  if (test.isError) return <Alert severity="error">{`Error: ${test.error.message}`}</Alert>;

  return (
    <Box>
      <TabContext value={value.toString()}>
        <TabList value={value} onChange={handleChange}>
          <Tab label="Test" value="0" />
          <Tab label="Snapshots" value="1" />
          <Tab label="Analyses" value="2" />
        </TabList>

        <TabPanel value="0">
          <Box sx={{ display: 'flex' }}>
            <NewSnapshotComponent type="web" projectId={parseInt(projectId)} testId={parseInt(testId)} />
            <DestroyProjectComponent projectId={parseInt(projectId)} />
          </Box>

          <Typography variant="h5">Test Details</Typography>
          <Typography>Name</Typography>
          <p>{test.data.name}</p>
          <Typography>Description</Typography>
          <p>{test.data.target}</p>
          <Typography>Actions</Typography>
          <pre>{test.data.actions ?? 'default'}</pre>
          <Typography>Scripts</Typography>
          <pre>{test.data.initScript ?? 'none'}</pre>
        </TabPanel>

        <TabPanel value="1">
          <SnapshotsList projectId={parseInt(projectId)} />
        </TabPanel>

        <TabPanel value="2">
          <AnalysisList type="web" projectId={parseInt(projectId)} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}
