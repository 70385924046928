import {
  CTSnapshot,
  CertificateSnapshot,
  DNSSnapshot,
  HTTPSnapshot,
  Job,
  Snapshot,
  SnapshotType
} from '@analyzer/client';
import { DNSData } from '@analyzer/core/lib/collector/collectors/dns.js';
import {
  AppConsoleMessage,
  EthereumTransaction,
  WalletInteraction,
  WebSocketRequest
} from '@analyzer/core/lib/collector/collectors/web.js';
import { LiquityEvent } from '@analyzer/core/lib/collector/plugins/liquity/index.js';
import { HTTPTransaction } from '@analyzer/core/lib/domain/models/snapshot-web.js';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  ChipProps,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography
} from '@mui/material';
import * as x509 from '@peculiar/x509';
import { Buffer } from 'buffer';
import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSnapshotListQuery, useSnapshotReadQuery } from '../api/snapshot.js';
import SummarizedLink from '../components/url.js';
import { asPEM, handleDownload, timeToNow } from '../components/utils.js';
import { useService } from '../providers/ServiceProvider.js';

function getFilenameFromUrl(url: string, defaultFilename = 'default.txt') {
  const { pathname } = new URL(url);
  const lastSegment = pathname.split('/').pop();
  return lastSegment && lastSegment.length ? lastSegment : defaultFilename;
}

function WebRequestView({ webRequests }: { webRequests: HTTPTransaction[] }) {
  const client = useService();

  const download = async (webRequest: HTTPTransaction) => {
    const { request, response } = webRequest;
    const filename = getFilenameFromUrl(request.url);
    const contents = await client.readResponseBody(response.id);
    handleDownload(contents, filename);
  };

  const sortedWebRequests = webRequests.sort((a, b) => a.request.url.localeCompare(b.request.url));

  return (
    <Box>
      <Typography variant="h6">HTTP Requests</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>SHA</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Size</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedWebRequests.map((webRequest, index) => (
              <TableRow key={index}>
                <TableCell>{webRequest.request.method}</TableCell>
                <TableCell>
                  <SummarizedLink url={webRequest.request.url} />
                </TableCell>
                <TableCell>{webRequest.response.body.sha256.slice(0, 16)} ...</TableCell>
                <TableCell>{webRequest.response.contentType}</TableCell>
                <TableCell>{webRequest.response.body.size ?? 'N/A'}</TableCell>
                <TableCell>{webRequest.response.status}</TableCell>
                <TableCell>
                  <Button variant="contained" size="small" onClick={() => download(webRequest)}>
                    Download
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function WebSocketView({ sockets }: { sockets: WebSocketRequest[] }) {
  return (
    <Box>
      <Typography variant="h6">Web Socket Requests</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>URL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sockets.map((socket, index) => (
              <TableRow key={index}>
                <TableCell>{socket.id}</TableCell>
                <TableCell>
                  <SummarizedLink url={socket.url} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function EthereumAddress({ address }: { address: string }) {
  return <SummarizedLink url={`https://etherscan.io/address/${address}`} title={address} />;
}

function EthereumBlock({ block }: { block: string }) {
  const n = parseInt(block, 16);
  return <SummarizedLink url={`https://etherscan.io/block/${n}`} title={n.toString()} />;
}

function EthereumChain({ chainId }: { chainId: string }) {
  switch (parseInt(chainId, 16)) {
    case 1:
      return <>Mainnet</>;
    case 3:
      return <>Ropsten</>;
    case 4:
      return <>Rinkeby</>;
    case 42:
      return <>Kovan</>;
    case 5:
      return <>Görli</>;
    case 100:
      return <>xDai</>;
    default:
      return <>Unknown</>;
  }
}

function EthereumMethod({ method }: { method: string }) {
  if (method === 'eth_requestAccounts') return <>eth_requestAccounts</>;

  return (
    <SummarizedLink
      url={`https://docs.infura.io/infura/networks/ethereum/json-rpc-methods/${method.toLowerCase()}`}
      title={method}
    />
  );
}

function EthereumTransactionResponse({ transaction }: { transaction: EthereumTransaction }) {
  switch (transaction.method) {
    case 'eth_accounts':
    case 'eth_requestAccounts':
      return transaction.response.map((address: string) => <EthereumAddress address={address} />);

    case 'eth_blockNumber':
      return <EthereumBlock block={transaction.response} />;

    case 'eth_chainId':
      return <EthereumChain chainId={transaction.response} />;

    default:
      return <pre>{JSON.stringify(transaction.response)}</pre>;
  }
}

function EthereumTransactionView({ transactions }: { transactions: EthereumTransaction[] }) {
  return (
    <Box>
      <Typography variant="h6">Ethereum RPC Requests</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell>Params</TableCell>
              <TableCell>Response</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((tx, index) => (
              <TableRow key={index}>
                <TableCell>
                  <EthereumMethod method={tx.method} />
                </TableCell>
                <TableCell>
                  <pre>{JSON.stringify(tx.params, null, 2)}</pre>
                </TableCell>
                <TableCell>
                  <EthereumTransactionResponse transaction={tx} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ConsoleMessageView({ messages }: { messages: AppConsoleMessage[] }) {
  return (
    <Box>
      <Typography variant="h6">Console Messages</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((message, index) => (
              <TableRow key={index}>
                <TableCell>{message.type}</TableCell>
                <TableCell>
                  <SummarizedLink url={message.location} />
                </TableCell>
                <TableCell>
                  <pre>{message.text}</pre>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function DNSView({ dnsData }: { dnsData: DNSData[] }) {
  return (
    <Box>
      <Typography variant="h6">Whois Data</Typography>
      {dnsData.map((dnsData, index) => (
        <Paper sx={{ p: 2, mt: 2 }} key={index}>
          <Typography variant="subtitle1">Hostname: {dnsData.hostname}</Typography>
          <List dense>
            {dnsData.records.map((record, i) => (
              <ListItem key={i}>
                <ListItemText primary={`${record.type}: ${record.entry}`} />
              </ListItem>
            ))}
          </List>
        </Paper>
      ))}
    </Box>
  );
}

function LiquityView({ liquityEvents }: { liquityEvents: LiquityEvent[] }) {
  const [liquity] = liquityEvents;
  if (!liquity)
    return (
      <>
        <Box>
          <Typography variant="h6">No Liquity Events</Typography>
        </Box>
      </>
    );

  return (
    <Box>
      <Typography variant="h6">Liquity Events</Typography>
      <Paper sx={{ mt: 2 }}>
        <List dense={true}>
          <ListSubheader>Metadata</ListSubheader>
          <ListItem>
            <ListItemText primary="Chain ID" secondary={liquity.chainId} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Deployment Date" secondary={liquity.deploymentDate.toString()} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Frontend Tag" secondary={liquity.frontendTag} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Start Block" secondary={liquity.startBlock} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Version" secondary={liquity.version} />
          </ListItem>
          <ListSubheader>Addresses</ListSubheader>
          {Object.entries(liquity.addresses).map(([key, value]) => (
            <ListItem key={key}>
              <ListItemText primary={`Address for '${key}'`} secondary={value} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Box>
  );
}

function WalletInteractionView({ interactions }: { interactions: WalletInteraction[] }) {
  return (
    <Box>
      <Typography variant="h6">Wallet Interactions</Typography>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Method</TableCell>
              <TableCell>Params</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {interactions.map((interaction, index) => (
              <TableRow key={index}>
                <TableCell>{interaction.method}</TableCell>
                <TableCell>
                  <pre>{JSON.stringify(interaction.params)}</pre>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function ScreenshotView({ data }: { data: Buffer | undefined }) {
  return (
    <Box>
      <Typography variant="h6">Screenshot</Typography>
      <img src={`data:image/png;base64,${data?.toString('base64')}`} alt="screenshot" />
    </Box>
  );
}

function WebSnapshotView({ snapshot }: { snapshot: HTTPSnapshot }) {
  const [value, setValue] = useState('1');
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  function chip(name: string, content: number) {
    const contentString = content > 99 ? 'n' : content.toString();
    return <Chip size="small" label={name} avatar={<Avatar>{contentString}</Avatar>} />;
  }

  function handleDownloadHarClick(filename: string, data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box>
      <Typography variant="h6">HTTP Snapshot</Typography>
      <Typography>{`Vantage Point: ${snapshot.node?.address ?? 'N/A'}`}</Typography>
      <Typography>Job: {JSON.stringify(snapshot.job) ?? 'N/A'}</Typography>
      <Typography>
        Test:
        <Link
          to={`/projects/${snapshot.project.id}/tests/${snapshot.test.id}`}
        >{`${snapshot.test.name} (${snapshot.test.target})`}</Link>
      </Typography>

      <Typography>Created: {snapshot.createdDate.toISOString()}</Typography>
      <Typography>Updated: {snapshot.updatedDate.toISOString()}</Typography>

      <Typography sx={{ color: snapshot.error ? 'error.main' : 'success.main' }}>
        Status: {snapshot.error ? `Error: ${snapshot.error}` : 'Success'}
      </Typography>

      <Button
        onClick={async () => {
          if (snapshot.trace) {
            const rawData = snapshot.trace;
            handleDownloadHarClick(`${snapshot.id}-trace.har`, rawData, 'application/json');
          }
        }}
      >
        Download HAR File
      </Button>

      <TabContext value={value}>
        <TabList onChange={handleChange}>
          <Tab label={chip('Requests', snapshot.requests.length)} value="1" />
          <Tab label={chip('Sockets', snapshot.sockets.length)} value="2" />
          <Tab label={chip('Transactions', snapshot.transactions.length)} value="3" />
          <Tab label={chip('Wallet', snapshot.wallet.length)} value="4" />
          <Tab label={chip('Console', snapshot.console.length)} value="5" />
          <Tab label={chip('Liquity', snapshot.data.length)} value="6" />
          <Tab label={chip('Screenshots', 1)} value="7" />
        </TabList>
        <TabPanel value="1">
          <WebRequestView webRequests={snapshot.requests} />
        </TabPanel>
        <TabPanel value="2">
          <WebSocketView sockets={snapshot.sockets} />
        </TabPanel>
        <TabPanel value="3">
          <EthereumTransactionView transactions={snapshot.transactions} />
        </TabPanel>
        <TabPanel value="4">
          <WalletInteractionView interactions={snapshot.wallet} />
        </TabPanel>
        <TabPanel value="5">
          <ConsoleMessageView messages={snapshot.console} />
        </TabPanel>
        <TabPanel value="6">
          <LiquityView liquityEvents={snapshot.data} />
        </TabPanel>
        <TabPanel value="7">
          <ScreenshotView data={snapshot.screenshot?.data} />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

function TransparencySnapshotView({ snapshot }: { snapshot: CTSnapshot }) {
  const records = snapshot.records.sort((a, b) => {
    const dateA = new Date(a.not_before);
    const dateB = new Date(b.not_before);
    return dateB.getTime() - dateA.getTime();
  });

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Transparency Log Snapshot</Typography>
      <Typography>{`Vantage Point: ${snapshot.node?.address ?? 'N/A'}`}</Typography>
      <Typography>Job: {JSON.stringify(snapshot.job) ?? 'N/A'}</Typography>
      <Typography>
        Root:
        <Link to={`/projects/${snapshot.project.id}/roots/${snapshot.root.id}`}>
          {snapshot.root.hostname}
        </Link>
      </Typography>
      <Typography>Created: {snapshot.createdDate.toISOString()}</Typography>
      <Typography>Updated: {snapshot.updatedDate.toISOString()}</Typography>
      <Typography>Records:</Typography>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Serial</TableCell>
              <TableCell>CN</TableCell>
              <TableCell>Valid From</TableCell>
              <TableCell>Valid To</TableCell>
              <TableCell>Issuer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((record, index) => (
              <TableRow key={index}>
                <TableCell>{record.serial_number}</TableCell>
                <TableCell>{record.name_value}</TableCell>
                <TableCell>{new Date(record.not_before).toISOString()}</TableCell>
                <TableCell>{new Date(record.not_after).toISOString()}</TableCell>
                <TableCell>{record.issuer_name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function CertificateSnapshotView({ snapshot }: { snapshot: CertificateSnapshot }) {
  const certificate = useMemo(() => new x509.X509Certificate(snapshot.certificate), [snapshot.certificate]);

  const download = () => {
    const filename = `${certificate.serialNumber}.pem`;
    const pem = asPEM(snapshot.certificate);
    handleDownload(pem, filename);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h6">Certificate</Typography>
      <Typography>{`Vantage Point: ${snapshot.node?.address ?? 'N/A'}`}</Typography>
      <Typography>Job: {JSON.stringify(snapshot.job) ?? 'N/A'}</Typography>
      <Button variant="contained" size="small" onClick={download}>
        Download
      </Button>

      <Typography>
        Resource:
        <Link to={`/projects/${snapshot.project.id}/assets/${snapshot.resource.id}`}>
          {`${snapshot.resource.hostname} @ ${snapshot.resource.address}:${snapshot.resource.port}`}
        </Link>
      </Typography>
      <Typography>Created: {snapshot.createdDate.toISOString()}</Typography>
      <Typography>Updated: {snapshot.updatedDate.toISOString()}</Typography>
      <Typography>Serial Number: {certificate.serialNumber}</Typography>
      <Typography>Subject: {certificate.subject}</Typography>
      <Typography>Issuer: {certificate.issuer}</Typography>
      <Typography>
        Algorithm: {certificate.signatureAlgorithm.name} {certificate.signatureAlgorithm.hash.name}
      </Typography>
      <Typography>Valid From: {certificate.notBefore.toISOString()}</Typography>
      <Typography>Valid Until: {certificate.notAfter.toISOString()}</Typography>
    </Box>
  );
}

function DNSSnapshotView({ snapshot }: { snapshot: DNSSnapshot }) {
  return (
    <Paper elevation={1} sx={{ padding: 2 }}>
      <Typography variant="h6">DNS Snapshot</Typography>
      <Typography>{`Vantage Point: ${snapshot.node?.address ?? 'N/A'}`}</Typography>
      <Typography>Job: {JSON.stringify(snapshot.job) ?? 'N/A'}</Typography>
      <Typography>
        Domain:
        <Link to={`/projects/${snapshot.project.id}/domains/${snapshot.domain.id}`}>
          {snapshot.domain.name}
        </Link>
      </Typography>
      <Typography>Created: {snapshot.createdDate.toISOString()}</Typography>
      <Typography>Updated: {snapshot.updatedDate.toISOString()}</Typography>
      <Typography>Records:</Typography>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Entry</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {snapshot.records.map((record, index) => (
              <TableRow key={index}>
                <TableCell>{record.type}</TableCell>
                <TableCell>{record.entry}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export function SnapshotView() {
  const { projectId, snapshotId, type } = useParams();
  if (!projectId || !snapshotId || !type) throw new Error('Missing parameter');

  const {
    data: snapshot,
    isLoading,
    isError,
    error
  } = useSnapshotReadQuery(type as SnapshotType, parseInt(projectId), parseInt(snapshotId));

  if (isLoading) return <CircularProgress />;
  if (isError) return <Alert severity="error">{`Error: ${error.message}`}</Alert>;

  switch (type) {
    case 'web':
      return <WebSnapshotView snapshot={snapshot as HTTPSnapshot} />;
    case 'certificate':
      return <CertificateSnapshotView snapshot={snapshot as CertificateSnapshot} />;
    case 'transparency':
      return <TransparencySnapshotView snapshot={snapshot as CTSnapshot} />;
    case 'dns':
      return <DNSSnapshotView snapshot={snapshot as DNSSnapshot} />;
  }
}

function SnapshotsDetails({ type, projectId }: { type: SnapshotType; projectId: number }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const snapshotList = useSnapshotListQuery(type, projectId, {
    limit: rowsPerPage,
    offset: page * rowsPerPage
  });

  if (snapshotList.isLoading) return <CircularProgress />;
  const snapshots = snapshotList.data || [];

  snapshots.sort((a, b) => b.updatedDate.getTime() - a.updatedDate.getTime());

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function getResourceDetails(snapshot: Snapshot) {
    switch (snapshot.type) {
      case 'certificate':
        return `${snapshot.resource.hostname} @ ${snapshot.resource.address}:${snapshot.resource.port}`;
      case 'web':
        return snapshot.test.target;
      case 'dns':
        return snapshot.domain.name;
      case 'transparency':
        return snapshot.root.hostname;
    }
  }

  function getSnapshotStatus(snapshot: Snapshot): JSX.Element {
    const isWebSnapshot = snapshot.type === 'web';
    const hasError = isWebSnapshot && !!snapshot.error;

    const label = hasError ? 'Error' : 'Success';
    const color: ChipProps['color'] = hasError ? 'error' : 'success';

    const chip = <Chip size="small" label={label} color={color} />;

    if (hasError && snapshot.error) {
      return (
        <Tooltip title={snapshot.error} arrow>
          {chip}
        </Tooltip>
      );
    }

    return chip;
  }

  return (
    <TableContainer>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Analysis</TableCell>
            <TableCell>Resource</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell>References</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Job</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {snapshots.map((snapshot) => (
            <TableRow key={snapshot.id}>
              <TableCell>
                <Link to={`/projects/${projectId}/snapshots/${snapshot.id}/${snapshot.type}`}>
                  {snapshot.id}
                </Link>
              </TableCell>
              <TableCell>
                {snapshot.analyses[0] ? (
                  <Link to={`/projects/${projectId}/analyses/${snapshot.analyses[0]!.id}`}>
                    {snapshot.analyses[0]!.id}
                  </Link>
                ) : (
                  'Not available'
                )}
              </TableCell>
              <TableCell>
                <Tooltip title={getResourceDetails(snapshot)} placement="top" arrow>
                  <span>{getResourceDetails(snapshot)}</span>
                </Tooltip>
              </TableCell>
              <TableCell>{timeToNow(snapshot.createdDate)}</TableCell>
              <TableCell>{timeToNow(snapshot.updatedDate)}</TableCell>
              <TableCell>{snapshot.count}</TableCell>
              <TableCell>{getSnapshotStatus(snapshot)}</TableCell>
              <TableCell>N/A</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              count={-1}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton={true}
              showLastButton={true}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export function SnapshotsView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter');

  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem(`snapshots-view-active-tab-${projectId}`);
    return savedTab ? parseInt(savedTab, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem(`snapshots-view-active-tab-${projectId}`, activeTab.toString());
  }, [activeTab, projectId]);

  const handleChange = (event: any, newTabValue: number) => {
    setActiveTab(newTabValue);
  };

  const snapshotTypes: SnapshotType[] = ['web', 'certificate', 'transparency', 'dns'];

  return (
    <Box>
      <Tabs value={activeTab} onChange={handleChange}>
        {snapshotTypes.map((type) => (
          <Tab key={type} label={type.toUpperCase()} />
        ))}
      </Tabs>
      {snapshotTypes.map((type, index) => (
        <Box key={type} hidden={activeTab !== index}>
          <SnapshotsDetails type={type} projectId={parseInt(projectId)} />
        </Box>
      ))}
    </Box>
  );
}
