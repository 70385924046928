import { Project, Test } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTestUpdateMutation } from '../../../api/test.js';

interface DisableTestProps {
  project: Project | undefined;
  test: Test | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onError: (error: Error) => void;
}

export function DisableTestDialog({ project, test, open, onClose, onConfirm, onError }: DisableTestProps) {
  const testUpdate = useTestUpdateMutation();

  if (!project || !test) return null;

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    test.enabled = !test.enabled;
    testUpdate
      .mutateAsync({ projectId: project.id, testId: test.id, test })
      .then(onConfirm)
      .catch(onError)
      .finally(onClose);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{test.enabled ? 'Disable' : 'Enable'} test?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {test.enabled ? 'disable' : 'enable'} test {test.name} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
