import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AlertsView } from './pages/AlertsView.js';
import { AlertView } from './pages/AlertView.js';
import { AnalysesView, AnalysisView } from './pages/AnalysisView';
import { AssetsView, AssetView } from './pages/AssetView.js';
import { Dashboard } from './pages/Dashboard';
import { ErrorPage } from './pages/ErrorPage';
import { JobsView } from './pages/JobsView.js';
import { Login } from './pages/Login';
import { Logout } from './pages/Logout';
import { MainView } from './pages/MainView';
import { ProjectsView } from './pages/ProjectsView';
import { ProjectView } from './pages/ProjectView';
import { RootsView, RootView } from './pages/RootView.js';
import { RulesView, RuleView } from './pages/RuleView.js';
import { SnapshotsView, SnapshotView } from './pages/SnapshotView';
import { TestsView, TestView } from './pages/TestView';
import { useAuth } from './providers/AuthProvider';
import { ServiceProvider } from './providers/ServiceProvider';
import { WorkersView, WorkerView } from './pages/WorkersView.js';
import { DevelopmentView } from './pages/DevelopmentView.js';

// Create public routes
const publicRouter = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    path: '/alert/:alertId',
    element: <AlertView />
  }
]);

// Create protected routes.
const protectedRouter = createBrowserRouter([
  {
    path: '/',
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        element: <MainView />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '/jobs',
        element: <JobsView />
      },
      {
        path: '/workers',
        element: <WorkersView />
      },
      {
        path: '/workers/:workerId',
        element: <WorkerView />
      },
      {
        path: '/projects',
        element: <ProjectsView />
      },
      {
        path: '/projects/:projectId',
        element: <ProjectView />
      },
      {
        path: '/projects/:projectId/snapshots',
        element: <SnapshotsView />
      },
      {
        path: '/projects/:projectId/snapshots/:snapshotId/:type',
        element: <SnapshotView />
      },
      {
        path: '/projects/:projectId/analyses',
        element: <AnalysesView />
      },
      {
        path: '/projects/:projectId/analyses/:analysisId',
        element: <AnalysisView />
      },
      {
        path: '/projects/:projectId/alerts',
        element: <AlertsView />
      },
      {
        path: '/projects/:projectId/analyses/:analysisId/alerts/:alertId',
        element: <AlertView />
      },
      {
        path: '/projects/:projectId/tests',
        element: <TestsView />
      },
      {
        path: '/projects/:projectId/tests/:testId',
        element: <TestView />
      },
      {
        path: '/projects/:projectId/assets',
        element: <AssetsView />
      },
      {
        path: '/projects/:projectId/assets/:assetId',
        element: <AssetView />
      },
      {
        path: '/projects/:projectId/roots',
        element: <RootsView />
      },
      {
        path: '/projects/:projectId/roots/:rootId',
        element: <RootView />
      },
      {
        path: '/projects/:projectId/rules',
        element: <RulesView />
      },
      {
        path: '/projects/:projectId/rules/:ruleId',
        element: <RuleView />
      },
      {
        path: '/development',
        element: <DevelopmentView />
      }
    ]
  }
]);

export function Routes() {
  const { authenticated } = useAuth();
  if (!authenticated) {
    return <RouterProvider router={publicRouter} />;
  }

  return (
    <ServiceProvider>
      <RouterProvider router={protectedRouter} />
    </ServiceProvider>
  );
}
