import { Project, Test } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useTestDestroyMutation } from '../../../api/test.js';

interface DeleteTestDialogProps {
  project: Project | undefined;
  test: Test | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onError: (error: Error) => void;
}

export function DeleteTestDialog({
  project,
  test,
  open,
  onClose,
  onConfirm,
  onError
}: DeleteTestDialogProps) {
  const testDestroy = useTestDestroyMutation();

  if (!project || !test) return null;

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    testDestroy
      .mutateAsync({ projectId: project.id, testId: test.id })
      .then(onConfirm)
      .catch(onError)
      .finally(onClose);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Delete test?</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to delete test {test.name} ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
