import { Project, Test } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTestUpdateMutation } from '../../../api/test.js';

interface EditTestDialogProps {
  project: Project | undefined;
  test: Test | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: (test: Test) => void;
  onError: (error: Error) => void;
}

export function EditTestDialog({ project, test, open, onClose, onConfirm, onError }: EditTestDialogProps) {
  const testUpdate = useTestUpdateMutation();
  const { register, handleSubmit } = useForm<Test>({ values: test });

  if (!project || !test) return null;

  const handleConfirm = handleSubmit(async ({ name, target, actions, enabled }) => {
    const update = { ...test, name, target, actions, enabled };
    try {
      await testUpdate.mutateAsync({ projectId: project.id, testId: test.id, test: update });
      onConfirm(test);
    } catch (error) {
      onError(error as Error);
    } finally {
      onClose();
    }
  });

  const handleClose = () => {
    onClose();
  };

  if (!test) return null;

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit test</DialogTitle>
      <form onSubmit={handleConfirm}>
        <DialogContent>
          <Stack spacing={2}>
            <TextField placeholder="Name" inputProps={register('name', { required: true })} />
            <TextField placeholder="Target URL" inputProps={register('target', { required: true })} />
            <TextField placeholder="Actions" inputProps={register('actions')} multiline rows={14} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Update</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
