import { Job, Project, Test } from '@analyzer/client';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnapshotCreateMutation } from '../../../api/snapshot.js';

interface SnapshotDialogProps {
  project: Project | undefined;
  test: Test | undefined;
  open: boolean;
  onClose: () => void;
  onConfirm: (job: Job) => void;
  onError: (error: Error) => void;
}

export function SnapshotDialog({ project, test, open, onClose, onConfirm, onError }: SnapshotDialogProps) {
  const snapshotCreate = useSnapshotCreateMutation('web');

  if (!project || !test) return null;

  const handleClose = () => {
    onClose();
  };

  const handleConfirm = () => {
    snapshotCreate
      .mutateAsync({ projectId: project.id, testId: test.id })
      .then(onConfirm)
      .catch(onError)
      .finally(onClose);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Snapshot</DialogTitle>
      <DialogContent>
        <DialogContentText>Are you sure you want to submit a snapshot job?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}
