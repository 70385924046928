import { Button, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useSnapshotCreateMutation } from '../../api/snapshot.js';

interface Properties {
  type: 'web' | 'certificate' | 'transparency' | 'dns';
  projectId: number;
  testId: number;
}

export function NewSnapshotComponent({ type, projectId, testId }: Properties) {
  const [open, setOpen] = useState(false);
  const snapshotCreate = useSnapshotCreateMutation(type);

  async function handler() {
    try {
      await snapshotCreate.mutateAsync({ projectId, testId: testId });
      setOpen(true);
    } catch (error) {
      console.error('Failed to create snapshot:', error);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button disabled={snapshotCreate.isLoading} onClick={handler}>
        Analyze
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Snapshot created successfully"
      />
    </>
  );
}
