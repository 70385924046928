import { Alert } from '@analyzer/client';
import { HTTPTransaction } from '@analyzer/core/lib/domain/models/snapshot-web.js';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAlertListQuery } from '../api/alert.js';

const severityColor = (severity: string) => {
  switch (severity) {
    case 'info':
      return 'success.main';
    case 'low':
      return 'success.main'; // Green
    case 'medium':
      return 'warning.main'; // Yellow
    case 'high':
      return 'error.main'; // Red
    default:
      return 'text.primary'; // Default text color
  }
};

function RenderHTTPTransaction(request: HTTPTransaction, title: string) {
  return (
    <Box sx={{ mb: 1 }}>
      <Typography variant="body2">URL: {request.request.url}</Typography>
      <Typography variant="body2">Type: {request.response.contentType}</Typography>
      <Typography variant="body2">Status: {request.response.status}</Typography>
    </Box>
  );
}

function RenderAlert({ alert }: { alert: Alert }) {
  switch (alert.type) {
    case 'content-change':
      return (
        <Paper sx={{ p: 2, mb: 1, display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="overline"
            sx={{
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
              marginRight: 2,
              color: severityColor(alert.severity)
            }}
          >
            Content Change
          </Typography>
          <Box>
            {RenderHTTPTransaction(alert.request0, 'Request 0')}
            {RenderHTTPTransaction(alert.request1, 'Request 1')}
          </Box>
        </Paper>
      );

    default:
      return (
        <Paper sx={{ p: 2, mb: 1, display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="overline"
            sx={{
              writingMode: 'vertical-rl',
              transform: 'rotate(180deg)',
              marginRight: 2,
              color: severityColor(alert.severity)
            }}
          >
            Content Change
          </Typography>
          <Box>{JSON.stringify(alert, null, 2)}</Box>
        </Paper>
      );
  }
}

export function AlertsView() {
  const { projectId } = useParams();
  if (!projectId) throw new Error('Missing parameter');

  const { data, isLoading, error } = useAlertListQuery(Number(projectId));

  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4, color: 'red' }}>
        <Typography variant="h6">Error:</Typography>
        <Typography>{error.message}</Typography>
      </Box>
    );
  }

  if (!data || data.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Typography variant="h6">No alerts found</Typography>
        <Typography variant="body1">Try adjusting your filters or refreshing the page.</Typography>
      </Box>
    );
  }

  const severityOrder = ['high', 'medium', 'low', 'info'];
  data.sort((a, b) => severityOrder.indexOf(a.severity) - severityOrder.indexOf(b.severity));

  return (
    <Box>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Found {data.length} alerts today
      </Typography>
      <Box>
        {data.map((alert) => (
          <RenderAlert key={alert.id} alert={alert} />
        ))}
      </Box>
    </Box>
  );
}
